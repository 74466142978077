















































































































































































































































import {
  computed, ref, useRoute, defineComponent, onMounted, useContext,
} from '@nuxtjs/composition-api';
import { SfHeading, SfButton, SfProperty } from '@storefront-ui/vue';
import { useUserOrder } from '~/modules/customer/composables/useUserOrder';
import GA4Purchase from '~/modules/fortytwo/googleanalytics4/components/GA4Purchase.vue';
import moment from 'moment';
import UserAddressDetails from '~/components/UserAddressDetails.vue';
import useFtGetCustomerOrderAddInfo from '~/modules/fortytwo/checkout/composables/useFtGetCustomerOrderAddInfo';
import { merge } from 'lodash-es';
import { useImage } from '~/composables';
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import { useApi } from "~/composables/useApi";
import { getItem } from "~/helpers/asyncLocalStorage";
import useFtSendFacebookPixel from '~/composables/FortyTwo/useFtSendFacebookPixel';
import useFtDynamicYieldEvent from '~/dy/useFtDynamicYieldEvent';

export default defineComponent({
  components: {
    SfHeading,
    SfButton,
    SfProperty,
    GA4Purchase,
    UserAddressDetails,
    SkeletonLoader,
  },
  setup(props, context) {
    context.emit('changeStep', 4);
    const { app, $cookies } = useContext();
    const { query } = useApi();
    const route = useRoute();
    const { search } = useUserOrder();
    const { imageSizes } = useImage();
    const { sendFacebookPixel } = useFtSendFacebookPixel();
    const order = ref(null);
    const hasOrder = computed(() => route.value.query.order as string ?? '');
    const hasOrderResult = ref(true);
    const orderNumber = computed(() => window.atob(route.value.query.order as string) ?? '');
    const totalQty = computed(()=>order.value.items.reduce((acc, curr)=> acc + curr.quantity_ordered , 0));
    const totalDiscount = computed(()=> -order.value.total.discounts.reduce((acc, curr)=> acc + curr.amount.value , 0));
    const hasDiscounts = computed(() => Math.abs(totalDiscount.value) > 0);
    const sendDYEvent  = useFtDynamicYieldEvent();

    const fetchOrderInfo = async (order_id) => {
      const orderResult = await search({ filter: { number: { eq: order_id } } });
      order.value = orderResult?.items[0] ?? null;
    };

    const getItemImage = (item_id) => {
      const itemIdDecode = window.atob(item_id);
      const image_path:any = Object.values(orderAddInfo.value).find((item:any) => item.id == itemIdDecode);
      return image_path?.image_url ?? '';
    }

    const orderAddInfo = ref({});
    const { getCustomerOrderAddInfo } = useFtGetCustomerOrderAddInfo();
    const fetchCustomerOrderAddInfo = async (order_id) => {
      const { data } = await getCustomerOrderAddInfo(order_id);
      if (data?.getCustomerOrderAddInfo) {
        orderAddInfo.value = await merge({}, orderAddInfo.value, data.getCustomerOrderAddInfo.items);
      }
    };

    const fetchDYEventAPI = async() => {
      //send Purchase Event to DY via API
      const ordernumber_data =  window.btoa(orderNumber.value) ?? '';
      await sendDYEvent("Purchase",ordernumber_data,'');
    }

    

    onMounted(async () => {
      if (hasOrder.value) {
        await Promise.all([fetchOrderInfo(orderNumber.value)]);
        if(order.value?.items?.length > 0) {
          fetchDYEventAPI();
          await Promise.all([fetchCustomerOrderAddInfo(orderNumber.value)]);

          // facabook pixel and conversions api
          await sendFacebookPixel('Purchase', order.value.number, order.value);
          await sendFacebookPixel('PageView', '');
        } else {
          hasOrderResult.value = false;
        }
      }
    });
    
    return {
      orderNumber,
      order,
      moment,
      totalQty,
      totalDiscount,
      hasDiscounts,
      getItemImage,
      imageSizes,
      hasOrder,
      hasOrderResult,
    };
  },
});
