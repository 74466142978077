<template><div id="GA4Purchase"></div></template>
<script>
import { ref,onMounted } from "@nuxtjs/composition-api";
import useFtProductInfoGA4 from '~/modules/fortytwo/googleanalytics4/composables/useFtProductInfoGA4';
import { useUser } from '~/modules/customer/composables/useUser';
import { sha256 } from 'js-sha256';
import { setItem,getItem } from "~/helpers/asyncLocalStorage";

export default {
  name: "GA4Purchase",
  props: {
    order: {
    type: Object,
    },
  },
  methods: {
    trackPurchase(data) {
      if (data.length == this.order.items.length){
          if(this?.user?.email){
          const userId = sha256.hex(this.user.email);
          this.$gtag.event("userIdSet", {
            'event_label': "userIdSet",
            'transaction_id': this.order?.number,
            'currency': this.order?.total?.base_grand_total?.currency ?? "SGD",
            'value': this.order?.total?.base_grand_total?.value ?? 0,
            'tax': this.order?.total?.total_tax?.value ?? 0,
            'shipping': this.order?.total?.total_shipping?.value ?? 0,
            'coupon': this.order?.total?.discounts?.[0]?.label ?? "",
            'purchase': { 
              'actionField': {
              'affiliation': '',
              },
            },
            'items': data,
            'user_id': userId
          });
        }
        this.$gtag.event("purchase", {
          'event_label': "purchase",
          'transaction_id': this.order?.number,
          'currency': this.order?.total?.base_grand_total?.currency ?? "SGD",
          'value': this.order?.total?.base_grand_total?.value ?? 0,
          'tax': this.order?.total?.total_tax?.value ?? 0,
          'shipping': this.order?.total?.total_shipping?.value ?? 0,
          'coupon': this.order?.total?.discounts?.[0]?.label ?? "",
          'enhanced_conversion_data': {
            "email": this.user?.email? sha256.hex(this.user.email) : ""
          },
          'purchase': { 
            'actionField': {
            'affiliation': '',
            },
          },
          'items': data,
        });
      }
    },
  },
  watch: {
    productInfoGA4(newValue) {
      this.trackPurchase(newValue);
    },
  },
  setup(props) {
    const { user, load: loadUser } = useUser();
    const productInfoGA4 = ref([]);
    const { getProductInfoGA4 } = useFtProductInfoGA4(); 
    const fetchCustomProductInfo = async (product_sku,product_name,product_price,product_quantity) => {
      const { data } = await getProductInfoGA4(window.btoa(product_sku));
      productInfoGA4.value.push({
          item_id: product_sku,
          item_name: product_name,
          price: product_price,
          item_category: data?.getProductInfoGA4?.category ?? "",
          item_brand: data?.getProductInfoGA4?.brand ?? "",
          quantity: product_quantity,
        }
        );
    };
    onMounted(async () => {
    const storageData_OID =  await getItem("OID") ?? '';
    if(!storageData_OID){
      if (user.value === null) {
        await loadUser();
      }
      if(props.order){
        for (const item of props.order.items) {
          await fetchCustomProductInfo(item.product_sku,item.product_name,item.product_unit_price,item.quantity_ordered);
        }
      }
      if(props.order?.number){
        await setItem("OID", window.btoa(props.order.number));
      } 
    }else{
      if (user.value === null) {
        await loadUser();
      }
      if(window.atob(storageData_OID) !== props.order.number){
        if(props.order){
          for (const item of props.order.items) {
            await fetchCustomProductInfo(item.product_sku,item.product_name,item.product_unit_price,item.quantity_ordered);
          }
        }
        await setItem("OID", window.btoa(props.order.number));
      }
    }
     
    });

    return{
      productInfoGA4,
      user,
    }
  }
};
</script>

  