import { useApi } from '~/composables/useApi';

export const useFtGetCustomerOrderAddInfo = () => {
  const { query } = useApi();

  const getCustomerOrderAddInfo:any = async (order_increment_id) =>{
    const myGraphqlQuery = `
      query getCustomerOrderAddInfo($order_increment_id: String!) {
        getCustomerOrderAddInfo(order_increment_id: $order_increment_id) {
          items {
            id
            image_url
          }
        }
      }
    `;
    const { data } = await query(myGraphqlQuery, {order_increment_id: order_increment_id});

    return {
      data,
    };
  }
  
  return {
    getCustomerOrderAddInfo,
  }
}


export default useFtGetCustomerOrderAddInfo;