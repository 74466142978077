import { useApi } from '~/composables/useApi';

export const useFtProductInfoGA4 = () => {
  const { query } = useApi();
  // 
  const getProductInfoGA4:any = async (product_uid) =>{
    const myGraphqlQuery = `
      query getProductInfoGA4($product_id:String!){
        getProductInfoGA4(product_id: $product_id){
          category
          brand
        }
      }
      `;  
    const { data } = await query(myGraphqlQuery, {product_id: product_uid});
    return {
      data,
    };
  }
  
  return {
    getProductInfoGA4,
  }
}


export default useFtProductInfoGA4;